////// Colors
$WHITE: #ffffff;
$WHITE_05P: rgba(255, 255, 255, .05);
$WHITE_10P: rgba(255, 255, 255, .10);
$WHITE_15P: rgba(255, 255, 255, .15);
$WHITE_25P: rgba(255, 255, 255, .25);
$WHITE_30P: rgba(255, 255, 255, .30);
$WHITE_45P: rgba(255, 255, 255, .45);
$WHITE_50P: rgba(255, 255, 255, .50);
$WHITE_65P: rgba(255, 255, 255, .65); // loading bg
$WHITE_80P: rgba(255, 255, 255, .80);
$FAKE_TRANSPARENT: rgb(161, 200, 224); //

$RED: #E02943; // statuses
$DK_RED: #8B0000; // Error background color
$BRIGHT_RED: #F23433;

$GREEN: #43C286; // statuses
$DK_GREEN: #228B22; // Used for .active button hover (tag filters selected and then hovered)
$BRIGHT_GREEN: #29BE1E;
$BRIGHT_GREEN_50P: rgba(41, 190, 30, 0.50);

$ORANGE: #FDAE33; // rules and work form editors

$DK_GREEN_2: #228722;
$THROWUP_GREEN: #adcd00;
$SUCCESS_GREEN: #22CB58;
$UNSUCCESS_RED: #E14942;
$GABBIE_GREEN: #2ab530;
$SARAH_GREEN: #277222;
$ORANGE: #FFA500;
$GABBIE_ORANGE: #ffa81d;
$SARAH_ORANGE: #925D27;
$SARAH_ORANGE_2: #ffa810; 
$SARAH_ORANGE_2_20P: rgba(255, 168, 29, 0.20);
$BLUE: #345CF6;
$DK_DK_BLUE: #214c66;
$DK_BLUE: #005cb9;
$DK_BLUE_2: #3087BC;
$LT_BLUE: #4197cb;
$LT_BLUE_20P: rgba(65, 151, 203, 0.2);
$LT_BLUE_25P: rgba(65, 151, 203, 0.25);
$LT_BLUE_50P: rgba(65, 151, 203, 0.5);
$GABBIE_PURPLE: #9a36f7;
$SARAH_PURPLE: #A079F3;
$GRIMACE_PURPLE: #6117DA;
$GRIMACE_PURPLE_2OP: rgba(97, 23, 218, 0.20);
$GRIMACE_PURPLE_5OP: rgba(97, 23, 218, 0.50);
$ABYSS_PURPLE: #37215B;
$BLACK: #000;
$ALMOST_BLACK: #181818;
$BLACK_38P: rgba(0, 0, 0, 0.38);
$BLACK_50P: rgba(0, 0, 0, 0.50);
$SARAH_BLACKISH_GRAY: #2E2E2E;
$SARAH_GRAY_2: #AAA;
$GRAY: #7b868c;
$DK_GRAY:  #363636;
$DK_GRAY_50P: rgba(54, 54, 54, 0.5);
$DD_GRAY: #373737;
$DISABLED_GRAY: #3c3c3c;
$DISABLED_GRAY_35P: rgba(60, 60, 60, 0.35);
$GRAY_65P: rgba(48,56,75, .65);
$DISABLED_GRAY_65P: rgba(60, 60, 60, 0.65);
$MED_GRAY: #EBEFF7;
$LT_GRAY: #F9FBFF;
$GABBIE_GRAY: #676767;
$SARAH_GRAY: #606060;
$SARAH_GRAY_2: #D9D9D9;
$MED_LT_GRAY: #8b8b8b;
$GRAY_40: rgba(136, 136, 136, 0.4);
$ANOTHER_GRAY1: #676767;
$GRAY_50P: rgba(48,56,75, .5);
$GRAY_5P: rgba(48,56,75, .05);
$DK_TEAMS_VIOLET: rgb(48,53,118);
$LT_TEAMS_VIOLET: rgb(123,133,228);

$INFO: #0f98d0;
$WARNING: #ffab00;
$DANGER: #fc1449;
$LT_DANGER: #fc1449c0;
$MICROSOFT_RED: #f04e1f;

$MODAL_BACKDROP_BLACK: rgba(24, 24, 24, 0.8);

// MORE GRAYS
$BG_GRAY: #181818;
$GRAY_DK: #262626;
$GRAY_MD: #3c3c3c;
$GRAY_LT: #686868;
$GRAY_XLT: #888888;

// Fonts/Tex
$font-weight-700: 700;
$font-weight-500: 500;
$font-weight-400: 400;
$font-weight-300: 300;
$font-weight-200: 200;
$font-weight-100: 100;

$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px; // default
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-26: 26px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-40: 40px;
$font-size-60: 60px;

// z-index
$z100: 100;
$z200: 200;
$z300: 300;
$z400: 400;
$z500: 500;
$z600: 600;
$z700: 700;
$z800: 800;
$z900: 900; // modal
$z901: 901; // modal close
$z1100: 1100; // veil backdrops
$z1200: 1200; // loading

// Misc.
$border-radius: 5px;
$disabled-opacity: .65;

// Circle
$border-circle: 50%;

// Component Dimensions
$header-height: 45px;
$console-header-height: 80px;
$navigation-open: 290px;
$navigation-closed: 70px;
$sub-nav-width: 150px;
$map-data-min-width: 425px;
$page-wrapper-padding: 20px;
$page-inner-spacing: 12px;
$input-height: 36px;

// BRANDING COLOR PALETTE COLORS  from 1-14-20 (ONLY TO BE USED ON NAVIGATION ITEMS CURRENTLY for representing branded "solutions" aka Hole Watch, etc.)
$W_DK_BLUE: #0063a7;
$W_LT_BLUE: #4197cb;
$W_GOLD: #f5c851;
$W_ORANGE: #ef823f;
$W_GREEN: #5bbc93;
$W_LAVENDER: #c6d8ff;
$W_PURPLE: #aa71ff;
$W_PINK: #f07da8;
$W_RED: #ff1b1c;
$W_TEAL: #7cffcb;
$W_DK_GRAY: #373a36;
$W_LT_GRAY: #7b868c;
$W_BG_DARK: #231F20;
$W_BG_LT: #d3d0cb;
$W_LT_TEAL: #bffff1;
$SNACKBAR_RED: #af0000;
$SNACKBAR_PINK: #FFCFCF;

// Meeting components
$MEETING_SIDEBAR_WIDTH: 380px;

// Console components
$MAIN_NAV_WIDTH: 90px;
$CONSOLE_HEADER_HEIGHT: 45px;
$CONSOLE_SIDEBAR_WIDTH: 280px;
$CONSOLE_SIDEBAR_XXL_WIDTH: 325px;
$DVR_CONTROL_HEIGHT: 115px;
$DVR_CONTROL_SHORT_HEIGHT: 50px;
$MAP_PADDING: 30px;
$MAP_DASHBOARD_PANEL_WIDTH: 400px;

// Additional colors
$WHISPER: #E9E9E9;
$LOCHMARA: #3873ae;
$GRAY_47: #474747;
$GRAY_48: #484848;
$GRAY_70: #707070;
$CIAN: #02c3c3;
$SNACKBAR_RED: #af0000;
$SNACKBAR_PINK: #FFCFCF;
$DEFAULT_RED: #FF0000;
$DEFAULT_GREEN: #008000;
$DEFAULT_GRAY: #808080;
$DEFAULT_YELLOW: #FFFF00;
$GRAY_INACTIVE: #212121;
$GRAY_PROGRESS_BAR_CONTAINER: #eee;
$GRAY_SELECTED_ITEM: #929292;
$AVATAR_ICON: #888888;
$AVATAR_BACKGROUND: #EEEEEE;

// Breakpoints
$sm: 567px;
$med: 768px;
$lg: 992px;
$xl: 992px;



// theming
body {
    --color: white;
    --font-family: 'Gilmer', sans-serif;
    --font-weight: 200;

    --spinner-color: rgba(255, 255, 255, .65);
    --scrollbar-color: $WHITE $GRAY_MD;

    --mdc-typography-button-letter-spacing: normal;
}
body.web-radio, body.teams, body.teams-dark {
    --header-icon-size: 35px;
    --header-icon-font-size: 1em;
    --text-input-width: auto;
    --map-padding: 5px;
    --scrollbar-color: unset;
}
body.console {
    --header-icon-size: 46px;
    --header-icon-font-size: 1.4em;
    --text-input-width: 100%;
    --map-padding: 30px;
}
body.web-radio, body.console {
    --bkg-color: #181818;
    --color: white;
    --hyperlink-color: white;
    --font-family: 'Gilmer', sans-serif;
    --font-weight: 200;
    --bkg-shadow-color: #181818;

    --header-border-color: #181818;

    --listings-color: #262626;
    --listings-border-color: #181818;

    --icon-bkg-color: rgba(255, 255, 255, .30);
    --icon-color: #ffffff;
    --icon-hover-bkg-color: #ffffff;
    --icon-hover-color: #000000;
    --icon-highlight-color: #000000;

    --scrollbar-width: 5px;
    --scrollbar-track-bkg-color: transparent;
    --scrollbar-thumb-border-radius: 0;
    --scrollbar-thumb-bkg-color: #ffffff;
    --scrollbar-thumb-hover-bkg-color: #686868;
    --scrollbar-track-color: $WHITE;
    --scrollbar-thumb-color: $GRAY_MD;

    --spinner-color: rgba(255, 255, 255, .65);
    --spinner-stroke-color: white;

    --input-border-highlight-color: transparent;
    
    --avatar-font-color: white;
    --avatar-background-color: black;

    --input-bottom-border-color: white;
    --input-background-color: transparent;

    --teams-input-background: none;
    --teams-input-color: none;

    --attachment-background-color: #181818;
    --attachment-font-color: white;

    --modal-background-color: #262626;
    --modal-font-color: white;
    --modal-hover-color: none;

    --menu-background-color: #373737;
    --menu-font-color: white;

    --message-bubble-incoming: #373737;
    --message-bubble-incoming-teams: rgb(48,53,118);
    --message-bubble-outgoing: #4197cb;
    --message-bubble-outgoing-teams: rgb(123,133,228);
    --message-bubble-outgoing-editing: #1C6795;
    --message-bubble-text-color: white;
    --message-bubble-text-color-teams: white;
    --message-sending-indicator-color: #7B868C;
    --message-sub-text: #888;

    --read-receipts-background-color: #d3d0cb;
    --read-receipts-color: black;
    --read-receipts-scrollbar-track-color: #686868;

    --menu-color: #D9D9D9;
    --menu-hover-color: #BFBFBF;

    --drag-and-drop-border-color: #4197cb;
}
body.teams {
    --bkg-color: #ebebeb;
    --color: black;
    --hyperlink-color: #4F52B2;
    --font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",system-ui,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Web",sans-serif;
    --font-weight: normal;
    --bkg-shadow-color: rgba(211, 211, 211, .6);
    --channel-button-border-color: #d1d1d1;

    --header-border-color: rgba(211, 211, 211, .6);

    --listings-color: #ebebeb;
    --listings-border-color: rgba(211, 211, 211, .6);

    --listings-bkg-color: #f0f0f0;
    --channel-container-bkg-color: #f5f5f5;

    --active-bkg-color: #ffffff;
    --hover-bkg-color: #fafafa;

    --icon-bkg-color: #ffffff;
    --icon-color: black;
    --icon-hover-bkg-color: #f5f5f5;
    --icon-hover-color: black;
    --icon-highlight-color: #5B5FC7;

    --scrollbar-width: 6px;
    --scrollbar-track-bkg-color: transparent;
    --scrollbar-thumb-border-radius: 8px;
    --scrollbar-thumb-bkg-color: rgba(97,97,97, 0.5);
    --scrollbar-thumb-hover-bkg-color: rgba(97,97,97, 0.5);
    --scrollbar-track-color: $WHITE;
    --scrollbar-thumb-color: $GRAY_MD;

    --spinner-color: #ebebeb;
    --spinner-stroke-color: #5B5FC7;

    --input-border-highlight-color: #5B5FC7;

    --avatar-font-color: #000000;
    --avatar-background-color: #ebebeb;

    --input-bottom-border-color: #5B5FC7;
    --input-background-color: #F0F0F0;

    --teams-input-background: white;
    --teams-input-color: black;
    --teams-input-shadow-color: rgba(211, 211, 211, .6);

    --attachment-background-color: #dedede;
    --attachment-font-color: black;

    --modal-background-color: #F5F5F5;
    --modal-font-color: black;
    --modal-hover-color: rgb(230, 230, 230);

    --menu-background-color: #FFFFFF;
    --menu-font-color: black;
    --menu-hover-color: #ebebeb;

    --user-search-background-color: #f5f5f5;
    --user-search-create-channel-background-color: rgb(255, 255, 255);
    --user-search-chip-background-color: rgb(240, 240, 240);
    --user-search-chip-color: black;
    --user-search-chip-hover-color: rgb(250, 250, 250);

    --message-bubble-incoming: #FFFFFF;
    --message-bubble-incoming-teams: #FFFFFF;
    --message-bubble-outgoing: #E8EBFA;
    --message-bubble-outgoing-teams: #E8EBFA;
    --message-bubble-outgoing-editing: #888;
    --message-bubble-text-color: black;
    --message-bubble-text-color-teams: black;
    --message-sending-indicator-color: #5B5FC7;
    --message-sub-text: #888;

    --notifications-bkg-color: #d9d9d9;
    --notifications-hover-bkg-color: #dfdfdf;

    --tab-indicator-active-color: #5B5FC7;
    --tab-indicator-inactive-color: #656565;

    --table-bkg-color: #ffffff;
    --table-header-border-bottom-color: #FDFDFD;
    --table-header-action-bkg-color: #F5F5F5;
    --table-row-border-bottom-color: #EFEFEF;
    --table-row-hover-bkg-color: #FAFAFA;
    --table-row-selected-bkg-color: #FAFAFA;
    --table-row-selected-highlight-color: #5B5FC7;

    --mat-menu-bkg-color: #FFFFFF;
    --mat-menu-item-hover-bkg-color: #F0F0F0;
    --mat-menu-item-color: black;
    --mat-menu-item-border-color: #999999;

    --mat-tooltip-bkg-color: #F5F5F5;

    --map-controls-bkg-color: #ffffff;
    --map-controls-color: black;
    --map-controls-hover-bkg-color: #f5f5f5;
    --map-controls-hover-color: black;
    --map-controls-active-bkg-color: #5B5FC7;
    --map-controls-active-color: #fafafa;

    --map-callout-bkg-color: white;
    --map-callout-color: black;
    --map-callout-border-color: #FDFDFD;
    --map-callout-shadow-color: rgba(0, 0, 0, 0.7);
    --map-callout-action-color: #292929;

    --map-callout-filter-bkg-color: #F5F5F5;
    --map-callout-filter-color: black;

    --map-marker-avatar-bkg-color: #ebebeb;
    --map-marker-avatar-online-color: #228b22;

    --read-receipts-background-color: #FFFFFF;
    --read-receipts-color: black;
    --read-receipts-scrollbar-track-color: transparent;

    --menu-color: #D9D9D9;
    --menu-hover-color: #BFBFBF;
    --drag-and-drop-border-color: #5B5FC7;
}
body.teams-dark {
    --bkg-color: #181818;
    --color: white;
    --hyperlink-color: #7F85F5;
    --font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",system-ui,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Web",sans-serif;
    --font-weight: normal;
    --bkg-shadow-color: rgba(47, 47, 47, .6);
    --channel-button-border-color: #545454;

    --header-border-color: #141414;

    --listings-color: #181818;
    --listings-border-color: #0a0a0a;

    --listings-bkg-color: #141414;
    --channel-container-bkg-color: #1f1f1f;

    --active-bkg-color: #292929;
    --hover-bkg-color: #242424;

    --icon-bkg-color: #292929;
    --icon-color: white;
    --icon-hover-bkg-color: #1f1f1f;
    --icon-hover-color: white;
    --icon-highlight-color: #7f85f5;
    
    --scrollbar-width: 6px;
    --scrollbar-track-bkg-color: transparent;
    --scrollbar-thumb-border-radius: 8px;
    --scrollbar-thumb-bkg-color: rgba(173,173,173, 0.5);
    --scrollbar-thumb-hover-bkg-color: rgba(173,173,173, 0.5);
    --scrollbar-track-color: rgba(173,173,173, 0.5);
    --scrollbar-thumb-color: #3c3c3c;

    --spinner-color: #181818;
    --spinner-stroke-color: #7f85f5;

    --input-border-highlight-color: #7f85f5;

    --avatar-font-color: #ffffff;
    --avatar-background-color: #1f1f1f;

    --input-bottom-border-color: #7f85f5;
    --input-background-color: #1F1F1F;

    --teams-input-background: #292929;
    --teams-input-color: white;
    --teams-input-shadow-color: rgba(0, 0, 0, 0.7);

    --attachment-background-color: #181818;
    --attachment-font-color: white;

    --modal-background-color: #262626;
    --modal-font-color: white;
    --modal-hover-color: #181818;

    --menu-background-color: #292929;
    --menu-font-color: white;
    --menu-hover-color: #3d3d3d;

    --user-search-background-color: #1f1f1f;
    --user-search-create-channel-background-color: rgb(41, 41, 41);
    --user-search-chip-background-color: rgb(20, 20, 20);
    --user-search-chip-color: white;
    --user-search-chip-hover-color: rgb(56, 56, 56);

    --message-bubble-incoming: #292929;
    --message-bubble-incoming-teams: #292929;
    --message-bubble-outgoing: #2F2F4A;
    --message-bubble-outgoing-teams: #2F2F4A;
    --message-bubble-text-color: white;
    --message-bubble-text-color-teams: white;
    --message-sending-indicator-color: #7f85f5;

    --notifications-bkg-color: #292929;
    --notifications-hover-bkg-color: #2f2f2f;

    --tab-indicator-active-color: #7f85f5;
    --tab-indicator-inactive-color: #656565;

    --table-bkg-color: #141414;
    --table-header-border-bottom-color: #202020;
    --table-header-action-bkg-color: #1F1F1F;
    --table-row-border-bottom-color: #2b2b2b;
    --table-row-hover-bkg-color: #242424;
    --table-row-selected-bkg-color: #242424;
    --table-row-selected-highlight-color: #7f85f5;

    --mat-menu-bkg-color: #292929;
    --mat-menu-item-hover-bkg-color: #484644;
    --mat-menu-item-color: #AAAAAA;
    --mat-menu-item-border-color: #292929;

    --mat-tooltip-bkg-color: #1F1F1F;

    --map-controls-bkg-color: #242424;
    --map-controls-color: white;
    --map-controls-hover-bkg-color: black;
    --map-controls-hover-color: white;
    --map-controls-active-bkg-color: #7f85f5;
    --map-controls-active-color: white;

    --map-callout-bkg-color: #141414;
    --map-callout-color: white;
    --map-callout-border-color: #202020;
    --map-callout-shadow-color: rgba(0, 0, 0, 0.7);
    --map-callout-action-color: white;

    --map-callout-filter-bkg-color: #141414;
    --map-callout-filter-color: white;

    --map-marker-avatar-bkg-color: #1f1f1f;
    --map-marker-avatar-online-color: #228b22;

    --read-receipts-background-color: #292929;
    --read-receipts-color: white;
    --read-receipts-scrollbar-track-color: transparent;
    --drag-and-drop-border-color: #7f85f5;

    --menu-color: #181818;
}
